export const books = [
  {
    author: "Жорж Сименон",
    title: "Три комнаты на Манхэттене",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/simenon/cover.jpeg",
    id: 1,
    tracks: "simenon",
  },
  {
    author: "Владимир Набоков",
    title: "Машенька",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/nabokov/cover.jpeg",
    id: 2,
    tracks: "nabokov",
  },
  {
    author: "Александр Колпакиди",
    title: "Проклятие Че Гевары",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/kolpakidi/cover.jpeg",
    id: 3,
    tracks: "kolpakidi",
  },
  {
    author: "Джордж Оруэлл",
    title: "Ферма (на белорусском языке)",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/oruell/cover.jpg",
    id: 4,
    tracks: "oruell",
  },
  {
    author: "Дипак Чопра",
    title: "Семь духовных законов успеха",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/chopra/cover.jpg",
    id: 5,
    tracks: "chopra",
  },
  {
    author: "Демьян Бедный",
    title: "Про землю, про волю, про рабочую долю",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/bedniy/cover.jpg",
    id: 6,
    tracks: "bedniy",
  },
  {
    author: "Борис Литвак",
    title: "7 шагов к стабильной самооценке",
    img: "https://audiobookbackend.s3.us-east-2.amazonaws.com/public/boris-litvak/cover.jpg",
    id: 7,
    tracks: "boris-litvak",
  },
];
